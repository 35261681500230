<template>
  <div>
    <template>
      <v-data-table
        :items="tests"
        :headers="headers"
      >
        <template v-slot:top>
          <v-tooltip top v-if="editable">
            Hozzáadás
            <template v-slot:activator="{on, attrs}">
              <v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialogs.addTest.show=true"><v-icon>fa-plus</v-icon></v-btn>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.isFinished="{item}">
          <v-tooltip top>
            {{item.isFinished?"Befejezett":"Befejezetlen"}}
            <template v-slot:activator="{on, attrs}">
              <v-icon v-on="on" v-bind="attrs" :color="item.isFinished? 'success' : 'red accent-4'">{{item.isFinished? "fa-check" : "fa-times"}}</v-icon>
            </template>
          </v-tooltip>
        </template>

        <template v-slot:item.actions="{item}">
          <v-tooltip top v-if="actions.logging/*&&!item.isFinished*/">
            Eredményrögzítés
            <template v-slot:activator="{on, attrs}">
              <v-btn icon v-on="on" v-bind="attrs" color="primary" :to="{name: 'test', params: {id: item.id}}"><v-icon>fa-edit</v-icon></v-btn>
            </template>
          </v-tooltip>
          <v-tooltip top v-if="actions.viewScores">
            Pontok megtekintése
            <template v-slot:activator="{on, attrs}">
              <v-btn icon v-on="on" v-bind="attrs" color="yellow accent-3" @click=""><v-icon>fa-star</v-icon></v-btn>
            </template>
          </v-tooltip>
          <v-tooltip top v-if="actions.delete&&!item.isFinished&&editable">
            Törlés
            <template v-slot:activator="{on, attrs}">
              <v-btn icon v-on="on" v-bind="attrs" color="red accent-4" @click="dialogs.deleteTest.testId=item.id,dialogs.deleteTest.show=true"><v-icon>fa-trash</v-icon></v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialogs.addTest.show"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-text>
            <span class="headline">Teszt hozzáadása</span>
          </v-card-text>
          <v-card-text>
            <v-container>
              <v-text-field
                v-model="dialogs.addTest.body.name"
                prepend-inner-icon="fa-running"
                label="Név"
                rounded
                outlined
              />
              <v-menu
                v-model="dialogs.addTest.showDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogs.addTest.body.date"
                    label="Dátum"
                    prepend-inner-icon="fa-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="dialogs.addTest.body.date"
                  @input="dialogs.addTest.showDateMenu = false"
                  :first-day-of-week="1"
                />
              </v-menu>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogs.addTest.show = false"
            >
              Mégsem
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="addTest()"
            >
              Hozzáadás
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogs.deleteTest.show"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-text>
            <span class="headline">Teszt törlése</span>
          </v-card-text>
          <v-card-text>
            <v-container>
              Biztosan szeretné törölni a tesztet?
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogs.deleteTest.show = false"
            >
              Mégsem
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="deleteTest()"
            >
              Törlés
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
export default {
  props: ['type', 'id', 'editable'],
  data: function() {
    return {
      tests: [],
      dialogs: {
        deleteTest: {
          show: false,
          testId: -1
        },
        addTest: {
          show: false,
          showDateMenu: false,
          body: {
            name: "",
            date: (new Date()).toISOString().substring(0,10)
          }
        }
      },
      headerList: {
        isFinished: {
          text: 'Állapot',
          align: "center",
          sortable: true,
          filterable: false,
          groupable: false,
          value: 'isFinished'
        },
        name: {
          text: 'Név',
          align: "center",
          sortable: true,
          filterable: false,
          groupable: false,
          value: 'name'
        },
        date: {
          text: 'Időpont',
          align: "center",
          sortable: true,
          filterable: false,
          groupable: false,
          value: 'date'
        },
        actions: {
          text: 'Műveletek',
          align: "center",
          sortable: true,
          filterable: false,
          groupable: false,
          value: 'actions'
        }
      },
      headers: [],
      actions: {
        logging: false,
        delete: false,
        viewScores: false
      }
    }

  },
  computed: {

  },
  methods: {
    addTest: function() {
      this.axios({url: `/test/${this.type}/${this.id}`, data: {...this.dialogs.addTest.body}, method: "POST"}).then((response) => {
        if(response.data.success) {
          this.dialogs.addTest.show=false;
          this.tests.push({...this.dialogs.addTest.body, isFinished: false, id: response.data.data.id})
          this.dialogs.addTest.body.name=""
          this.dialogs.addTest.body.date=  (new Date()).toISOString().substring(0,10)
          this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
        }
      })
    },
    deleteTest: function() {
      this.axios({url: '/test/'+this.type+"/"+this.id+"/"+this.dialogs.deleteTest.testId, method: "DELETE"}).then((response) => {
        if(response.data.success) {
          this.dialogs.deleteTest.show=false;
          this.tests.splice(this.tests.findIndex(t => t.id==this.dialogs.deleteTest.testId),1)
          this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
        }
      })
    },
    getTests: function() {
      this.axios({url: "/test/list/"+this.type+"/"+this.id, method: "GET"}).then((response) =>{
        if(response.data.success) {
          this.tests=response.data.data.tests
          switch(this.type) {
            case "group":
              this.headers = [this.headerList.name, this.headerList.date, this.headerList.isFinished, this.headerList.actions]
              this.actions.logging=true;
              this.actions.delete=true;
              this.actions.viewScores=false;
            break;
            case "kidAthlete":

            break;
          }
        }
      })
    },
  },
  mounted() {
    this.getTests()
  }
}
</script>

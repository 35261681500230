var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"items":_vm.tests,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.editable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"success"},on:{"click":function($event){_vm.dialogs.addTest.show=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-plus")])],1)]}}],null,false,1560245042)},[_vm._v(" Hozzáadás ")]):_vm._e()]},proxy:true},{key:"item.isFinished",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.isFinished? 'success' : 'red accent-4'}},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.isFinished? "fa-check" : "fa-times"))])]}}],null,true)},[_vm._v(" "+_vm._s(item.isFinished?"Befejezett":"Befejezetlen")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.actions.logging/*&&!item.isFinished*/)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","to":{name: 'test', params: {id: item.id}}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-edit")])],1)]}}],null,true)},[_vm._v(" Eredményrögzítés ")]):_vm._e(),(_vm.actions.viewScores)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"yellow accent-3"},on:{"click":function($event){}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-star")])],1)]}}],null,true)},[_vm._v(" Pontok megtekintése ")]):_vm._e(),(_vm.actions.delete&&!item.isFinished&&_vm.editable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red accent-4"},on:{"click":function($event){_vm.dialogs.deleteTest.testId=item.id,_vm.dialogs.deleteTest.show=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-trash")])],1)]}}],null,true)},[_vm._v(" Törlés ")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogs.addTest.show),callback:function ($$v) {_vm.$set(_vm.dialogs.addTest, "show", $$v)},expression:"dialogs.addTest.show"}},[_c('v-card',[_c('v-card-text',[_c('span',{staticClass:"headline"},[_vm._v("Teszt hozzáadása")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"prepend-inner-icon":"fa-running","label":"Név","rounded":"","outlined":""},model:{value:(_vm.dialogs.addTest.body.name),callback:function ($$v) {_vm.$set(_vm.dialogs.addTest.body, "name", $$v)},expression:"dialogs.addTest.body.name"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Dátum","prepend-inner-icon":"fa-calendar","readonly":"","rounded":"","outlined":""},model:{value:(_vm.dialogs.addTest.body.date),callback:function ($$v) {_vm.$set(_vm.dialogs.addTest.body, "date", $$v)},expression:"dialogs.addTest.body.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogs.addTest.showDateMenu),callback:function ($$v) {_vm.$set(_vm.dialogs.addTest, "showDateMenu", $$v)},expression:"dialogs.addTest.showDateMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.dialogs.addTest.showDateMenu = false}},model:{value:(_vm.dialogs.addTest.body.date),callback:function ($$v) {_vm.$set(_vm.dialogs.addTest.body, "date", $$v)},expression:"dialogs.addTest.body.date"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogs.addTest.show = false}}},[_vm._v(" Mégsem ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addTest()}}},[_vm._v(" Hozzáadás ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogs.deleteTest.show),callback:function ($$v) {_vm.$set(_vm.dialogs.deleteTest, "show", $$v)},expression:"dialogs.deleteTest.show"}},[_c('v-card',[_c('v-card-text',[_c('span',{staticClass:"headline"},[_vm._v("Teszt törlése")])]),_c('v-card-text',[_c('v-container',[_vm._v(" Biztosan szeretné törölni a tesztet? ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogs.deleteTest.show = false}}},[_vm._v(" Mégsem ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteTest()}}},[_vm._v(" Törlés ")])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
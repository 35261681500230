<template>
  <div>
    <template>
      <v-container>
        <v-row>
          <v-col cols="12" :sm="5">
            <v-menu
              v-model="dates.menus.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dates.dates.start"
                  label="Kezdő időpont"
                  prepend-inner-icon="fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  rounded
                  outlined
                />
              </template>
              <v-date-picker
                v-model="dates.dates.start"
                :first-day-of-week="1"
                @input="dates.menus.start = false, getAttendanceRegister()"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" :sm="5">
            <v-menu
              v-model="dates.menus.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dates.dates.end"
                  label="Befejező időpont"
                  prepend-inner-icon="fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  rounded
                  outlined
                />
              </template>
              <v-date-picker
                v-model="dates.dates.end"
                :first-day-of-week="1"
                @input="dates.menus.end = false, getAttendanceRegister()"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" :sm="2">
            <v-tooltip top>
              Hozzáadás
              <template v-slot:activator="{on, attrs}">
                <v-btn fab :disabled="!editable" color="success" v-on="on" v-bind="attrs" @click="dialogs.addRegister.show=true"><v-icon>fa-plus</v-icon></v-btn>
              </template>
            </v-tooltip>
            <v-tooltip top>
              Tömeges hozzáadás
              <template v-slot:activator="{on, attrs}">
                <v-btn fab :disabled="!editable" color="primary" v-on="on" v-bind="attrs" @click="dialogs.addManyRegister.show=true"><v-icon>fa-calendar</v-icon></v-btn>
              </template>
            </v-tooltip>
            <v-tooltip top>
              Excel-táblázat készítése
              <template v-slot:activator="{on, attrs}">
                <v-btn :href="xlsxURL" target="_blank" v-on="on" v-bind="attrs" fab color="warning"><v-icon>fa-file-excel</v-icon></v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="register"
          >
          <template v-slot:top>
            A kipipáltak az edzésen jelen voltak.
          </template>
          <template v-for="slot in dateSlots"  v-slot:[slot.slot]="{item}" >
            <span v-if="item['name']=='Törlés'">
              <v-btn icon color="red accent-4" @click="dialogs.deleteRegister.date=slot.value, dialogs.deleteRegister.show=true"><v-icon>fa-trash</v-icon></v-btn>
            </span>
            <span v-else-if="item['name']=='Jegyzetek'">
              {{item[slot.value]}}
            </span>
            <span v-else>
              <v-checkbox :key="tableKey" v-model="item[slot.value]" readonly @click="changeAttendance(slot.value,item)"/>
            </span>
          </template>
        </v-data-table>
      </v-container>
      <v-row justify="center">
        <v-dialog
          v-model="dialogs.addRegister.show"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-text>
              <span class="headline">Jelenléti ív hozzáadása</span>
            </v-card-text>
            <v-card-text>
              <v-container>
                <v-menu
                  v-model="dialogs.addRegister.showDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dialogs.addRegister.register.datetime"
                      label="Időpont"
                      prepend-inner-icon="fa-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="dialogs.addRegister.register.datetime"
                    :first-day-of-week="1"
                    @input="dialogs.addRegister.showDateMenu = false"
                  />
                </v-menu>
                <v-text-field
                  v-model="dialogs.addRegister.register.notes"
                  label="Jegyzetek"
                  rounded
                  outlined
                  prepend-inner-icon="fa-sticky-note"
                />
                <v-select
                  v-model="dialogs.addRegister.register.missingKids"
                  prepend-inner-icon="fa-bed"
                  label="Hiányzó kölyökatléták"
                  rounded
                  outlined
                  multiple
                  chips
                  :items="kidAthletes"
                  item-text="fullname"
                  item-value="id"
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.addRegister.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="addRegister()"
              >
                Hozzáadás
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogs.addManyRegister.show"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-text>
              <span class="headline">Jelenléti ívek hozzáadása</span>
            </v-card-text>
            <v-card-text>
              <v-container>
                <v-menu
                  v-model="dialogs.addManyRegister.showDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox
                      v-model="dialogs.addManyRegister.register.dates"
                      multiple
                      chips
                      label="Időpontok"
                      prepend-inner-icon="fa-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="dialogs.addManyRegister.register.dates"
                    multiple
                    :first-day-of-week="1"
                  />
                </v-menu>
                <v-text-field
                  v-model="dialogs.addManyRegister.register.notes"
                  label="Jegyzetek"
                  rounded
                  outlined
                  prepend-inner-icon="fa-sticky-note"
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.addManyRegister.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="addManyRegister()"
              >
                Hozzáadás
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogs.deleteRegister.show"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-text>
              <span class="headline">Jelenléti ív törlése</span>
            </v-card-text>
            <v-card-text>
              <v-container>
                Biztos, hogy törli a {{dialogs.deleteRegister.date.replaceAll('-','.')}}.-i jelenléti ívet?
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.deleteRegister.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteRegister()"
              >
                Törlés
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: ['groupId', 'trainings', 'kidAthletes', 'editable'],
  data: function() {
    return {
      tableKey: 0,
      message: '',
      register: [],
      headers: [],
      dateSlots: [],
      dates: {
        dates: {
          start: this.addMonths(new Date(), -1).toISOString().substring(0,10),
          end: (new Date()).toISOString().substring(0,10)
        },
        menus: {
          start: false,
          end: false
        }
      },
      dialogs: {
        addRegister: {
          show: false,
          showDateMenu: false,
          register: {
            datetime:  (new Date()).toISOString().substring(0,10),
            notes: '',
            missingKids: []
          }
        },
        addManyRegister: {
          show: false,
          showDateMenu: false,
          register: {
            dates: [],
            notes: '',
          }
        },
        deleteRegister: {
          show: false,
          date:  (new Date()).toISOString().substring(0,10)
        }
      }
    }

  },
  computed: {
    xlsxURL: function() {
        return `${this.axios.defaults.baseURL}group/${this.groupId}/attendance/XLSX/${this.dates.dates.start}/${this.dates.dates.end}`;
    }
  },
  methods: {
    addManyRegister: function() {

      this.axios({url: '/group/'+this.groupId+"/attendance/massManage", method: "POST", data: this.dialogs.addManyRegister.register}).then((response) => {
        if(response.data.success) {
          response.data.data.attendanceRegisters.forEach(AR => {
            this.register.forEach(r => {
              if(r.id!=null) {
                r[AR.datetime]=true
              }
            })
            this.headers.push({
              text: `${AR.datetime.replaceAll('-','.')}.`,
              align: "left",
              sortable: false,
              filterable: false,
              groupable: false,
              value: AR.datetime
            })
            this.headers = this.headers.sort((a,b) => {
              if(a.text=='Név') return -1
              if(b.text<a.text) return 1;
              else if (b.text>a.text) return -1;
              else return 0;
            })
            this.dateSlots.push({value: AR.datetime, slot: 'item.'+AR.datetime})
            this.register[0][AR.datetime]=this.dialogs.addManyRegister.register.notes;

          })

          this.dialogs.addManyRegister.show=false;
          this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént')
          this.tableKey++
        }
      })
    },
    addRegister: function() {
      this.dialogs.addRegister.register.missingKids=this.dialogs.addRegister.register.missingKids.map(k => {
        return {
          id: k
        }
      })
      this.axios({url: '/group/'+this.groupId+"/attendance", method: "POST", data: this.dialogs.addRegister.register}).then((response) => {
        if(response.data.success) {
          this.register.forEach(r => {
            if(r.id!=null) {
              r[this.dialogs.addRegister.register.datetime]=this.dialogs.addRegister.register.missingKids.find(k => k.id==r.id)==undefined
            }
          })
          this.headers.push({
            text: `${this.dialogs.addRegister.register.datetime.replaceAll('-','.')}.`,
            align: "left",
            sortable: false,
            filterable: false,
            groupable: false,
            value: this.dialogs.addRegister.register.datetime
          })
          this.headers = this.headers.sort((a,b) => {
            if(a.text=='Név') return -1
            if(b.text<a.text) return 1;
            else if (b.text>a.text) return -1;
            else return 0;
          })
          this.dateSlots.push({value: this.dialogs.addRegister.register.datetime, slot: 'item.'+this.dialogs.addRegister.register.datetime})
          this.register[0][this.dialogs.addRegister.register.datetime]=this.dialogs.addRegister.register.notes;

          this.dialogs.addRegister.register.missingKids=[];
          this.dialogs.addRegister.show=false;
          this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént')
          this.tableKey++
        }
      })
    },
    deleteRegister: function() {
      this.axios({url: `/group/${this.groupId}/attendanceRegister/${this.dialogs.deleteRegister.date}`, method: "DELETE"}).then(response => {
        if(response.data.success) {
          this.headers.splice(this.headers.findIndex(h => h.value==this.dialogs.deleteRegister.date),1)
          this.dialogs.deleteRegister.show=false;
          this.$store.commit('setSnack','A törlés sikeresen megtörtént')
        }
      })
    },
    changeAttendance: function(date, item) {
      if(this.editable)
        this.axios({url: '/group/'+this.groupId+"/attendance/"+date+"/"+item.id, method: item[date]? "DELETE" : "PUT"}).then((response) => {
          if(response.data.success){
            item[date]=!item[date]
            this.tableKey++

          }

        })
    },
    getAttendanceRegister: function() {
      this.axios({url: "/group/"+this.groupId+"/attendance", params: {...this.dates.dates}, method: 'GET'}).then(response => {
        if(response.data.success) {
          const firstrows = (this.editable?[{id: null, name: 'Törlés'}]:[]).concat([{id: null, name: "Jegyzetek"}])
          this.headers = [{
            text: "Név",
            align: "right",
            sortable: false,
            filterable: false,
            groupable: false,
            value: "name",
            date: false
          }]
          this.dateSlots=[];
          response.data.data.dates.forEach(d => {
            firstrows[this.editable?1:0][d.date]=d.notes
            this.headers.push({
              text: `${d.date.replaceAll('-','.')}.`,
              align: "left",
              sortable: false,
              filterable: false,
              groupable: false,
              value: d.date,
              date: true
            })
            this.dateSlots.push({value: d.date, slot: 'item.'+d.date})
          })
          this.register = firstrows.concat(response.data.data.datatable.map(kA => {
            var returnVal = {id: kA.id, name: this.kidAthletes.find(k =>k.id==kA.id).fullname};
            response.data.data.dates.forEach(d => {
              returnVal[d.date] = kA.attendance[d.date]!=undefined
            })
            return returnVal;
          }))

        }
      })
    },
  },
  mounted() {
    this.getAttendanceRegister()
  }
}
</script>

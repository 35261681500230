<template>
    <div>
        <h1>Csoportvezető {{ leaders.length == 1 ? "személy" : "személyek" }}</h1>
        <p>A csoportvezető személyek teljes jogkörben kezelhetik a csoportot.</p>
        <v-data-table :items="leaders" :headers="tables.leaders.headers" disable-pagination hide-default-footer>
            <template v-slot:top>
                <v-tooltip top>
                    Társcsoportvezető hozzáadása
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" fab @click="dialogs.add.show = true" color="success">
                            <v-icon>fa-user-plus</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.delete="{ item }">
                <v-tooltip top>
                    {{ item.id == $store.getters.userId ? 'Önmagát nem törölheti' : 'Társcsoportvezető törlése' }}
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" icon v-if="item.id == $store.getters.userId"
                            color="grey lighten-1">
                            <v-icon>fa-trash</v-icon>
                        </v-btn>
                        <v-btn v-on="on" v-bind="attrs" icon v-else color="red accent-4"
                            @click="dialogs.delete.leaderId = item.id">
                            <v-icon>fa-trash</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog v-model="dialogs.add.show" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Hozzáadás</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-select v-model="dialogs.add.leaderId" :items="coaches.filter(c => leaders.findIndex(l => l.id===c.id)===-1)" item-text="fullname" item-value="id" prepend-inner-icon="fa-user"
                                label="Csoportvezető" rounded outlined />
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogs.add.show = false">
                            Mégsem
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="addLeader()" v-if="dialogs.add.leaderId !== null">
                            Hozzáadás
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogs.delete.show" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Csoportvezető törlése</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            Biztos benne, hogy törli a csoportvezetőt?
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogs.deleteGroup.show = false">
                            Mégsem
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="deleteLeader()">
                            Törlés
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ['groupId', 'leaders'],
    data: function () {
        return {
            coaches: [],
            dialogs: {
                delete: {
                    show: false,
                    leaderId: null
                },
                add: {
                    show: false,
                    leaderId: null
                }
            },
            tables: {
                leaders: {
                    headers: [
                        {
                            text: "Csoportvezető",
                            align: "center",
                            sortable: false,
                            filterable: false,
                            groupable: false,
                            value: "fullname"
                        },
                        {
                            text: "Törlés",
                            align: "center",
                            sortable: false,
                            filterable: false,
                            groupable: false,
                            value: "delete"
                        }
                    ]
                }
            }
        }
    },
    computed: {

    },
    watch: {
        'dialogs.delete.leaderId': function (newVal, oldVal) {
            if (newVal !== null) {
                this.dialogs.delete.show = true;
            }
        },
        'dialogs.add.show': function () {
            if (this.coaches.length == 0) {
                this.getCoaches();
            }
        }
    },
    methods: {
        addLeader: function () {
            this.axios({ url: `group/leader/${this.groupId}`, method: "PUT", data: { newLeaderId: this.dialogs.add.leaderId } }).then(response => {
                if (response.data.success) {
                    this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.');
                    this.dialogs.add.show = false;
                    this.dialogs.add.leaderId=null;
                    this.leaders.push(this.coaches.find(c => c.id == this.dialogs.add.leaderId));
                }
            })
        },
        deleteLeader: function () {
            this.axios({ url: `group/${this.groupId}/leader/${this.dialogs.delete.leaderId}`, method: "DELETE" }).then(response => {
                if (response.data.success) {
                    this.$store.commit('setSnack', 'A törlés sikeresen megtörtént');
                    this.leaders.splice(this.leaders.findIndex(l => l.id == this.dialogs.delete.leaderId), 1)
                    this.dialogs.delete.show = false;
                }
            })
        },
        getCoaches: function () {
            this.axios({ url: "user/coachList", method: "GET" }).then((response) => {
                if (response.data.success) {
                    this.coaches = response.data.data.coaches;
                }
            })
        }
    },
    mounted() {

    }
}
</script>

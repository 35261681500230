<template>
	<div>
		<template>
			<v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>{{group.name}}</v-toolbar-title>
      </v-toolbar>
			<v-tabs :vertical="!isMobilephone">
				<v-tab>
					<v-icon left>fa-child</v-icon>
					Alapadatok és kölyökatléták
				</v-tab>
				<v-tab>
					<v-icon left>fa-clipboard-check</v-icon>
					Jelenléti ív
				</v-tab>
				<v-tab>
					<v-icon left>fa-running</v-icon>
					Tesztek
				</v-tab>
				<v-tab>
					<v-icon left>fa-calendar-day</v-icon>
					Edzések időpontjai
				</v-tab>
				<v-tab v-if="groupOwner">
					<v-icon left>fa-trash</v-icon>
					Csoport törlése
				</v-tab>
				<v-tab v-if="$store.getters.userRole>=roles.clubAdmin">
					<v-icon left>fa-user-cog</v-icon>
					Csoportvezetők kezelése
				</v-tab>
				<v-tab-item> <!--Alapadatok és kölykök-->
					<v-simple-table>
						<tbody>
							<tr>
								<th style="text-align: right">Név</th>
								<td colspan="2" style="text-align: center">{{group.name}}</td>
							</tr>
							<tr>
								<th style="text-align: right">Rövid név</th>
								<td style="text-align: center">{{group.shortname}}</td>
							</tr>
							<tr v-if="group.Leader&&group.Leader.length>0">
								<th style="text-align: right">Csoportvezető{{group.Leader.length>1?"k":""}}</th>
								<td style="text-align: center">{{group.Leader.map(L=>L.fullname).join(', ')}}</td>
							</tr>
							<tr v-if="group.MIRClub||group.School">
								<th style="text-align: right">Egyesület/iskola</th>
								<td style="text-align: center">{{group.MIRClub.name || group.School.name}}</td>
							</tr>
							<tr>
								<td style="text-align: center" colspan="2"><v-btn rounded :disabled="!groupOwner" @click="dialogs.edit.show=true, dialogs.edit.changedGroup.name=group.name,dialogs.edit.changedGroup.shortname=group.shortname" color="blue"><v-icon>fa-edit</v-icon>Szerkesztés</v-btn></td>
							</tr>
						</tbody>
					</v-simple-table>
					<h1>Kölyökatléták</h1>
					<span v-if="group.KidAthletes&&group.KidAthletes.filter(KA => KA.ageByYears>config.maxAgeOfKidAthlete).length>0" class="red--text">Amennyiben egy atléta neve piros, idősebb a kölyökatlétika korcsoportnál, tehát több mint {{config.maxAgeOfKidAthlete}} éves.</span>
					<v-btn v-if="groupOwner&&group.KidAthletes" @click="dialogs.KidAthlete.show=true, dialogs.KidAthlete.new=true" color="success" rounded :disabled="group.KidAthletes.length>=config.maxKidAthletesInGroup">Új kölyökatléta hozzáadása<v-icon>fa-plus</v-icon></v-btn>
					<v-data-table
						:items="group.KidAthletes"
						:headers="tables.kidAthletes.headers"
						v-if="group.KidAthletes"
					>
						<template v-slot:item.fullname="{item}">
							<span v-if="item.ageByYears>config.maxAgeOfKidAthlete" class="red--text">{{item.fullname}}</span>
							<span v-else>{{item.fullname}}</span>
						</template>
						<template v-slot:item.actions="{item}">
							<v-tooltip top>
								<template v-slot:activator="{on, attrs}">
									<v-btn
										v-on="on"
										v-bind="attrs"
										@click="openEditKidAthleteDialog(item)"
										icon
										color="info"
										:disabled="!groupOwner"
									>
										<v-icon>fa-pen</v-icon>
									</v-btn>
								</template>
								Kölyökatléta adatainak módosítása
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{on, attrs}">
									<v-btn
										v-on="on"
										v-bind="attrs"
										@click="dialogs.deleteKidAthleteFromGroup.show=true, dialogs.deleteKidAthleteFromGroup.KAId=item.id"
										icon
										color="red accent-4"
										:disabled="!groupOwner"
									>
										<v-icon>fa-trash</v-icon>
									</v-btn>
								</template>
								Kölyökatléta törlése a csoportból
							</v-tooltip>
						</template>
					</v-data-table>
				</v-tab-item><!--/Alapadatok és kölykök-->

				<v-tab-item><!--Jelenléti ív-->
					<attendanceRegistry v-if="group.KidAthletes" :editable="groupOwner" :kidAthletes="group.KidAthletes" :groupId="this.$route.params.id" :trainings="group.Trainings"/>
				</v-tab-item><!--/Jelenléti ív-->

				<v-tab-item><!--Tesztek-->
					<tests :editable="groupOwner" type="group" :id="$route.params.id"/>
				</v-tab-item><!--/Tesztek-->
				<v-tab-item><!--Edzések időpontjai-->
					<v-data-table
						:items="group.Trainings"
						:headers="tables.trainings.headers"
						v-if="group.Trainings"
					>
						<template v-slot:top>
							<v-tooltip top>
								Hozzáadás
								<template v-slot:activator="{on, attrs}">
									<v-btn :disabled="!groupOwner" fab color="success" v-on="on" v-bind="attrs" @click="dialogs.addTraining.show=true"><v-icon>fa-plus</v-icon></v-btn>
								</template>
							</v-tooltip>
						</template>

						<template v-slot:item.day="{item}">
							{{dayOfWeek(item.dayOfWeek)}}
						</template>
						<template v-slot:item.delete="{item}">
							<v-tooltip top>
								<template v-slot:activator="{on, attrs}">
									<v-btn
										v-on="on"
										v-bind="attrs"
										:disabled="!groupOwner"
										@click="dialogs.deleteTraining.show=true, dialogs.deleteTraining.TId=item.id"
										icon
										color="red accent-4"
									>
										<v-icon>fa-trash</v-icon>
									</v-btn>
								</template>
								Edzés törlése
							</v-tooltip>
						</template>
					</v-data-table>
				</v-tab-item><!--/Edzések időpontjai-->
				<v-tab-item v-if="groupOwner"><!--Törlés-->
					<groupDelete :groupId="group.id" :groupOwner="groupOwner"/>
				</v-tab-item><!--/törlés-->
				<v-tab-item v-if="$store.getters.userRole>=roles.trainer">
					<leaders :groupId="group.id" :leaders="group.Leader"/>
				</v-tab-item>
			</v-tabs>

		  <v-row justify="center">
		    <v-dialog
		      v-model="dialogs.edit.show"
		      persistent
		      max-width="600px"
		    >
		      <v-card>
		        <v-card-title>
		          <span class="headline">Szerkesztés</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									v-model="dialogs.edit.changedGroup.name"
									prepend-inner-icon="fa-users"
									label="Név"
									rounded
									outlined
								/>
								<v-text-field
									v-model="dialogs.edit.changedGroup.shortname"
									prepend-inner-icon="fa-info-circle"
									label="Rövid név"
									rounded
									outlined
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialogs.edit.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="change()"
								v-if="dialogs.edit.changedGroup.name.length>0&&dialogs.edit.changedGroup.shortname.length>0"
		          >
		            Módosítás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    	</v-dialog>
				<v-dialog
					v-model="dialogs.deleteGroup.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Csoport törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli a csoportot?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteGroup.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteGroup()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
		      v-model="dialogs.KidAthlete.show"
		      persistent
		      max-width="600px"
		    >
		      <v-card>
		        <v-card-title>
		          <span class="headline">{{dialogs.KidAthlete.new?'Új kölyökatléta hozzáadása':'Kölyökatléta módosítása'}}</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-switch
									v-model="dialogs.KidAthlete.kidAthlete.male"
									:label="dialogs.KidAthlete.kidAthlete.male?'Fiú':'Lány'"
									:color="dialogs.KidAthlete.kidAthlete.male?'blue':'pink'"
								/>
								<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.title"
									label="Titulus"
									rounded
									outlined
								/>
								<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.lastname"
									label="Vezetéknév"
									rounded
									outlined
								/>
								<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.firstname"
									label="Keresztnév"
									rounded
									outlined
								/>
								<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.othername"
									label="Egyéb név"
									rounded
									outlined
								/>
								<!--<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.mothers_name"
									label="Anyja neve"
									rounded
									outlined
								/>-->
								<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.birthplace"
									label="Születési hely"
									rounded
									outlined
									prepend-inner-icon="fa-map-marker"
								/>
								<v-menu
					        v-model="dialogs.KidAthlete.showBirthdatePicker"
					        :close-on-content-click="false"
					        :nudge-right="40"
					        transition="scale-transition"
					        offset-y
					        min-width="auto"
					      >
					        <template v-slot:activator="{ on, attrs }">
					          <v-text-field
					            v-model="dialogs.KidAthlete.kidAthlete.birthdate"
					            label="Születésnap"
					            prepend-inner-icon="fa-calendar"
					            readonly
											rounded
											outlined
					            v-bind="attrs"
					            v-on="on"
					          ></v-text-field>
					        </template>
					        <v-date-picker
					          v-model="dialogs.KidAthlete.kidAthlete.birthdate"
										:first-day-of-week="1"
					          @input="dialogs.KidAthlete.showBirthdatePicker = false"
					        ></v-date-picker>
					      </v-menu>
								<!--<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.social_security_number"
									label="TAJ-szám"
									rounded
									outlined
								/>-->
								<v-text-field
									v-model="dialogs.KidAthlete.kidAthlete.notes"
									label="Jegyzetek"
									rounded
									outlined
									prepend-inner-icon="fa-sticky-note"
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialogs.KidAthlete.show = false, resetKidAthleteDialog()"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="newKidAthlete()"
								v-if="dialogs.KidAthlete.new"
		          >
		            Hozzáadás
		          </v-btn>
							<v-btn
		            color="blue darken-1"
		            text
		            @click="editKidAthlete()"
								v-else
		          >
		            Módosítás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    	</v-dialog>
				<v-dialog
					v-model="dialogs.deleteKidAthleteFromGroup.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Kölyökatléta törlése csoportból</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli a kölyökatlétát a csoportból?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteKidAthleteFromGroup.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteKidAthleteFromGroup()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.addTraining.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Edzés hozzáadása</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-select
									rounded
									outlined
									prepend-inner-icon="fa-calendar-day"
									:items="daysOfWeek"
									item-text="day"
									item-value="dayValue"
									v-model="dialogs.addTraining.training.dayOfWeek"
								/>
					      <v-menu
					        v-model="dialogs.addTraining.showTimeMenu"
					        :close-on-content-click="false"
					        :nudge-right="40"
					        transition="scale-transition"
					        offset-y
					        max-width="290px"
					        min-width="290px"
					      >
					        <template v-slot:activator="{ on, attrs }">
					          <v-text-field
					            v-model="dialogs.addTraining.training.time"
					            label="Idő"
					            prepend-inner-icon="fa-clock"
											rounded
											outlined
					            readonly
					            v-bind="attrs"
					            v-on="on"
					          ></v-text-field>
					        </template>
					        <v-time-picker
					          v-if="dialogs.addTraining.showTimeMenu"
					          v-model="dialogs.addTraining.training.time"
										format="24hr"
					          full-width
					          @click:minute="dialogs.addTraining.showTimeMenu=false"
					        ></v-time-picker>
					      </v-menu>
								<v-text-field
									v-model="dialogs.addTraining.training.place"
									prepend-inner-icon="fa-map-marker"
									rounded
									outlined
									label="Helyszín"
								/>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.addTraining.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								v-if="dialogs.addTraining.training.place&&dialogs.addTraining.training.dayOfWeek&&dialogs.addTraining.training.time"
								@click="addTraining()"
							>
								Hozzáadás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.deleteTraining.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Edzés törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli az edzést?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteTraining.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteTraining()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
	</template>
	</div>
</template>

<script>
	import router from '../router';
	import attendanceRegistry from '../components/groupAttendanceRegistry.vue'
	import tests from '../components/tests.vue'
	import groupDelete from '../components/group-delete'
	import leaders from '../components/group-leaders'
	import roles from '../plugins/roles'
	export default {
		name: 'group',
		components: {
			tests,
			attendanceRegistry,
			groupDelete,
			leaders
		},
		data: function(){
			return {
				roles,
        group: {},
        dialogs: {
					edit: {
						show: false,
						changedGroup: {name: '', shortname: ''}
					},
					deleteGroup: {
						show: false
					},
					KidAthlete: {
						show: false,
						new: true,
						editId: undefined,
						kidAthlete: {
							title: "",
							firstname: "",
							lastname: "",
							othername: "",
							male: false,
							birthplace: "",
							birthdate: "2015-09-20",
							mothers_name: "",
							social_security_number: "",
							notes: ""
						}
					},
					deleteKidAthleteFromGroup: {
						show: false,
						KAId: undefined
					},
					addTraining: {
						show: false,
						showTimeMenu: false,
						training: {
							time: '13:00',
							place: '',
							dayOfWeek: '1'
						}
					},
					deleteTraining: {
						show: false,
						TId: undefined
					}
        },
				tables: {
					kidAthletes: {
						headers: [
							{
								text: "Név",
								align: "center",
						    sortable: true,
						    filterable: false,
						    groupable: false,
								value: "fullname"
							},
							{
								text: "Születési hely",
								align: "center",
						    sortable: true,
						    filterable: false,
						    groupable: false,
								value: "birthplace"
							},
							{
								text: "Születési dátum",
								align: "center",
						    sortable: true,
						    filterable: false,
						    groupable: false,
								value: "birthdate"
							},
							/*{
								text: "Anyja neve",
								align: "center",
						    sortable: false,
						    filterable: false,
						    groupable: false,
								value: "mothers_name"
							},*/
							{
								text: "Jegyzetek",
								align: "center",
						    sortable: false,
						    filterable: false,
						    groupable: false,
								value: "notes"
							},
							{
								text: "Műveletek",
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "actions"
							}
						]
					},
					trainings: {
						headers: [
							{
								text: 'Nap',
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "day"
							},
							{
								text: 'Időpont',
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "time"
							},
							{
								text: 'Helyszín',
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "place"
							},
							{
								text: 'Törlés',
								align: "center",
								sortable: false,
								filterable: false,
								groupable: false,
								value: "delete"
							}
						]
					}
				}
      }
		},
		computed: {
			daysOfWeek() {
				return [
					{dayValue: 1, day: 'Hétfő'},
					{dayValue: 2, day: 'Kedd'},
					{dayValue: 3, day: 'Szerda'},
					{dayValue: 4, day: 'Csütörtök'},
					{dayValue: 5, day: 'Péntek'},
					{dayValue: 6, day: 'Szombat'},
					{dayValue: 0, day: 'Vasárnap'},
				].filter(d => this.group.Trainings? this.group.Trainings.filter(t => t.dayOfWeek%7==d.dayValue).length<this.config.maxTrainingPerDay : true)
			},
			groupOwner() {
				return this.group.Leader.findIndex(L => L.id==this.$store.getters.userId)!==-1;
			}
		},
		watch: {

		},
		methods: {
			change: function() {
				this.axios({url: "group/"+this.$route.params.id, method: "PUT", data: {changed: this.dialogs.edit.changedGroup}}).then((response) => {
					if(response.data.success) {
						for (const [key, value] of Object.entries(this.dialogs.edit.changedGroup)) {
							this.group[key] = value
						}
						this.dialogs.edit.show=false;
					}
				})
			},
			newKidAthlete: function() {
				this.axios({url: "kidathlete/", method: "POST", data: {kidathlete: {...this.dialogs.KidAthlete.kidAthlete, GroupId: this.$route.params.id}}}).then((response) => {
					if(response.data.success) {
						this.group.KidAthletes.push({...this.dialogs.KidAthlete.kidAthlete,fullname: this.fullname(this.dialogs.KidAthlete.kidAthlete.title, this.dialogs.KidAthlete.kidAthlete.firstname, this.dialogs.KidAthlete.kidAthlete.lastname, this.dialogs.KidAthlete.kidAthlete.othername) ,GroupId: this.$route.params.id, id: response.data.data.id, ageByYears: response.data.data.ageByYears})
						this.resetKidAthleteDialog();
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
		       }
				})
			},
			editKidAthlete: function() {
				this.axios({url: 'kidathlete/'+this.dialogs.KidAthlete.editId, method: "PUT", data: {changed: {...this.dialogs.KidAthlete.kidAthlete}}}).then((response) => {
					if(response.data.success) {
						const kidAthlete = this.group.KidAthletes.find(KA => KA.id==this.dialogs.KidAthlete.editId);
						["male", "title", "firstname", "lastname","othername", "birthdate", "birthplace", "mothers_name", "social_security_number", "notes"].forEach(i => {
							kidAthlete[i] = this.dialogs.KidAthlete.kidAthlete[i];
						})
						kidAthlete.fullname = this.fullname(this.dialogs.KidAthlete.kidAthlete.title,this.dialogs.KidAthlete.kidAthlete.firstname, this.dialogs.KidAthlete.kidAthlete.lastname,this.dialogs.KidAthlete.kidAthlete.othername)
						this.resetKidAthleteDialog();
						this.$store.commit('setSnack','A módosítás sikeresen megtörtént.')
					}
				})
			},
			deleteKidAthleteFromGroup: function() {
				this.axios({url: "kidathlete/"+this.dialogs.deleteKidAthleteFromGroup.KAId, method: "PUT", data: {changed: {GroupId: null}}}).then((response) => {
					if(response.data.success) {
						this.dialogs.deleteKidAthleteFromGroup.show=false;
						this.group.KidAthletes.splice(this.group.KidAthletes.findIndex(KA => KA.id==this.dialogs.deleteKidAthleteFromGroup.KAId),1);
						this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
					}
				})
			},
			addTraining: function() {
				this.axios({url: "group/"+this.$route.params.id+"/training", method: "POST", data: {training: this.dialogs.addTraining.training}}).then((response) => {
					if(response.data.success) {
						this.group.Trainings.push({...this.dialogs.addTraining.training, id: response.data.data.id})
						this.dialogs.addTraining.show=false
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
					 }
				})
			},
			deleteTraining: function() {
				this.axios({url: "group/"+this.$route.params.id+"/training/"+this.dialogs.deleteTraining.TId, method: "DELETE"}).then((response) => {
					if(response.data.success) {
						this.dialogs.deleteTraining.show=false;
						this.group.Trainings.splice(this.group.Trainings.findIndex(T => T.id==this.dialogs.deleteTraining.TId),1);
						this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
					}
				})
			},
			fullname: function(title, firstname, lastname, othername) {
				return [
						title || "",
						lastname || "",
					 	firstname || "",
						othername || ""
				].join(' ').trim()
			},
			openEditKidAthleteDialog: function(KidAthlete) {
				this.dialogs.KidAthlete.editId=KidAthlete.id;
				this.dialogs.KidAthlete.new=false;
				["male", "title", "firstname", "lastname","othername", "birthdate", "birthplace", "mothers_name", "social_security_number", "notes"].forEach(i => {
					this.dialogs.KidAthlete.kidAthlete[i] = KidAthlete[i]
				})
				this.dialogs.KidAthlete.show=true
			},
			resetKidAthleteDialog: function() {
				this.dialogs.KidAthlete = {
					show: false,
					new: true,
					editId: undefined,
					kidAthlete: {
						title: "",
						firstname: "",
						lastname: "",
						othername: "",
						male: false,
						birthplace: "",
						birthdate: "2015-09-20",
						mothers_name: "",
						social_security_number: "",
						notes: ""
					}
				}
			}
		},
		mounted(){
			this.axios({url: "group/"+this.$route.params.id, method: "GET"}).then((response) => {
        if(response.data.success) {
          this.group = response.data.data.group
        }
			})
		}
	}
</script>
